<template>
  <div class="m-5">
    <div class="d-flex mb-4 ml-3">
      <div>
        <el-page-header @back="goBack" content="編輯 FAQs"></el-page-header>
      </div>
    </div>

    <el-row>
      <el-col :sm="12" :md="6" v-for="(item, key) in faqList" :key="key">
        <el-card style="margin: 15px" shadow="hover">
          <div class="card-body">
            <router-link :to="{ name: item.routerName }">
              <div style="font-size: 100px">
                <i class="fas fa-pen-square"></i>
              </div>
              <div>
                <span>{{ item.faqName }}</span>
              </div>
            </router-link>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      faqList: [
        {
          faqName: "關於我們",
          routerName: "EditFaqMain"
        },
        {
          faqName: "Instructors FAQ",
          routerName: "EditInstructorsFaq"
        }
      ]
    };
  },
  async mounted() {},
  methods: {
    handleRouterChange(name) {
      this.$router.push({
        name: name
      });
    },
    goBack() {
      this.$router.push({
        name: "Home"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.el-button {
  outline: none;
}
a {
  text-decoration: none;
  color: #909399;
}
.card-body {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0px;
  padding-bottom: 5px;
  height: 180px;
  text-align: center;
}

::v-deep .el-card__body {
  padding-top: 5px;
  padding-bottom: 15px;
}

::v-deep .el-page-header__content {
  font-size: 28px;
}
</style>
